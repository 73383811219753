import { Check } from "@mui/icons-material"
import { Button, Card, CardContent, Divider } from "@mui/material"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import FormAutocomplete from "../../../shared/FormAutocomplete"
import FormNumber from "../../../shared/FormNumber"
import notificationError from "../../../shared/notificationError"
import notificationSuccess from "../../../shared/notificationSuccess"
import { editHoliday } from "../holiday.api"

export default function HolidayEdit(props: any) {

    const dispatch = useDispatch()

    const [values, setValues] = useState<any>({
        year: 0,
        employee_id: "",
        value: ""
    })

    useEffect(() => {
        setValues({
            id: props.data.id,
            year: props.data.year,
            employee_id: props.data.employee_id,
            value: props.data.value
        })
    }, [])

    const handleChangeValues = (e: any) => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        dispatch<any>(editHoliday(values))
            .unwrap()
            .then((response: any) => {
                if (response.status === 200) {
                    notificationSuccess("success edit holiday")
                        .then(() => {
                            props.onClose(true)
                        })
                }
                else {
                    notificationError(response)
                }
            })
    }

    return (
        <Card>
            <CardContent className="custom-form">
                <form onSubmit={handleSubmit}>
                    <Divider />
                    <br />
                    <FormNumber label="Year" name="year" value={values.year} onChange={handleChangeValues} required />
                    <br />
                    <Divider />
                    <br />
                    {values.id && <FormAutocomplete url="/employee" label="Employee" name="employee_id" value={values.employee_id} onChange={handleChangeValues} />}
                    <br />
                    <Divider />
                    <br />
                    <FormNumber label="Total Holiday" name="value" value={values.value} onChange={handleChangeValues} required />
                    <br />
                    <Divider />
                    <br />
                    <div className="form-footer">
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => props.onClose(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<Check />}
                            type="submit"
                        >
                            Update Holiday
                        </Button>
                    </div>
                </form>
            </CardContent>
        </Card >
    )
}