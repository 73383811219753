import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../state";

export const createCalendar = createAsyncThunk('createCalendar', async (data: any) => {
    try {
        const response = await axios({
            method: 'post',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/calendar',
            data: data
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const deleteCalendar = createAsyncThunk('deleteCalendar', async (id: string) => {
    try {
        const response = await axios({
            method: 'delete',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/calendar?id=' + id,
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const editCalendar = createAsyncThunk('editCalendar', async (data: any) => {
    try {
        const response = await axios({
            method: 'put',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/calendar',
            data: data
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const createHoliday = createAsyncThunk('createHoliday', async (data: any) => {
    try {
        const response = await axios({
            method: 'post',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/holiday',
            data: data
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const deleteHoliday = createAsyncThunk('deleteHoliday', async (id: string) => {
    try {
        const response = await axios({
            method: 'delete',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/holiday?id=' + id,
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const editHoliday = createAsyncThunk('editHoliday', async (data: any) => {
    try {
        const response = await axios({
            method: 'put',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/holiday',
            data: data
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const createHolidayRequest = createAsyncThunk('createHolidayRequest', async (data: any) => {
    try {
        const response = await axios({
            method: 'post',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/holiday/request',
            data: data
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const deleteHolidayRequest = createAsyncThunk('deleteHolidayRequest', async (id: string) => {
    try {
        const response = await axios({
            method: 'delete',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/holiday/request?id=' + id,
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const editHolidayRequest = createAsyncThunk('editHolidayRequest', async (data: any) => {
    try {
        const response = await axios({
            method: 'put',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/holiday/request',
            data: data
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const isHolidayEnough = createAsyncThunk('isHolidayEnough', async () => {
    try {
        const response = await axios({
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('cqmToken')
            },
            url: baseUrl + '/holiday/isenough',
        })
        return response
    } catch (error: any) {
        return error.response
    }
})