import { Autocomplete, Container, Grid, TextField, Typography } from "@mui/material";
import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setBreadcumbs, setIsShowBack, setTitle } from "../../../containers/containers.reducer";
import checkAccess from "../../../shared/checkAccess";
import { RootState } from "../../../store";
import { setYearSelected } from "../holiday.reducer";
import Calendar from "./Calendar";
import Holiday from "./Holiday";
import HolidayRequest from "./HolidayRequest";
import { isHolidayEnough } from "../holiday.api";
import Swal from "sweetalert2";

export default function HolidayContainer() {

    const dispatch = useDispatch<any>()

    const { yearSelected } = useSelector((state: RootState) => state.holiday)

    useEffect(() => {
        dispatch(setTitle("Holiday"))
        dispatch(setIsShowBack(false))
        dispatch(setBreadcumbs([]))
        dispatch(isHolidayEnough()).unwrap()
            .then((response: any) => {
                if (!response.data.data) {
                    Swal.fire({
                        icon: "warning",
                        title: "Active holiday kamu belum mencukupi",
                        text: "Segera lapor Head dan HR, atau pengajuan cuti ini akan di DECLINE"
                    })
                }
            })
    }, [])

    return (
        <Container maxWidth="xl">
            <br />
            <Typography variant="h4">Calendar</Typography>
            <br />
            <Calendar />
            {checkAccess("HOLIDAY_VIEW") && (
                <Fragment>
                    <br />
                    <Typography variant="h4">Holiday</Typography>
                    <Grid container>
                        <Grid item md={2}>
                            <Autocomplete
                                options={[2022, 2023, 2024]}
                                value={yearSelected}
                                onChange={(e, value) => dispatch(setYearSelected(value))}
                                renderInput={(params) => <TextField {...params} size="small" placeholder={"Choose year"} />}
                            />
                        </Grid>
                    </Grid>
                    <br />
                    <Holiday />
                </Fragment>
            )}
            {checkAccess("HOLIDAY_REQUEST_VIEW") && (
                <Fragment>
                    <br />
                    <Typography variant="h4">Holiday Request</Typography>
                    <br />
                    <HolidayRequest />
                </Fragment>
            )}
        </Container >
    )
}