import { createSlice } from '@reduxjs/toolkit'
import { createCalendar, deleteCalendar, editCalendar, createHoliday, deleteHoliday, editHoliday, createHolidayRequest, deleteHolidayRequest, editHolidayRequest, isHolidayEnough } from './holiday.api'

export interface HolidayState {
    loading: boolean,
    yearSelected: any,
    timeGetData: number
}
const initialState: HolidayState = {
    loading: false,
    yearSelected: new Date().getFullYear(),
    timeGetData: 0
}

export const holidaySlice = createSlice({
    name: 'holiday',
    initialState,
    reducers: {
        setYearSelected: (state, action) => {
            state.yearSelected = action.payload;
        },
        setTimeGetData: (state, action) => {
            state.timeGetData = action.payload;
        }
    },
    extraReducers: (builder) => {

        builder.addCase(createCalendar.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createCalendar.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createCalendar.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(deleteCalendar.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(deleteCalendar.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteCalendar.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(editCalendar.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(editCalendar.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(editCalendar.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(createHoliday.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createHoliday.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createHoliday.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(deleteHoliday.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(deleteHoliday.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteHoliday.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(editHoliday.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(editHoliday.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(editHoliday.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(createHolidayRequest.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createHolidayRequest.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createHolidayRequest.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(deleteHolidayRequest.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(deleteHolidayRequest.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteHolidayRequest.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(editHolidayRequest.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(editHolidayRequest.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(editHolidayRequest.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(isHolidayEnough.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(isHolidayEnough.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(isHolidayEnough.rejected, (state, action) => {
            state.loading = false
        })

    },
})

export const {
    setYearSelected,
    setTimeGetData
} = holidaySlice.actions;

export default holidaySlice.reducer